import { useRef } from "react";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const useDeepEqualCallback = (callback: any, deps: any[]) => {
  const callbackRef = useRef(callback);
  const cachedDeps = useRef(cloneDeep(deps));
  if (!isEqual(deps, cachedDeps.current)) {
    callbackRef.current = callback;
    cachedDeps.current = cloneDeep(deps);
  }
  return callbackRef.current;
};

export default useDeepEqualCallback;
