import { useState, useEffect } from "react";
import Auth from "../auth/index";
import httpMethods from "../utils/http";
import defaultCreateFetchHandler from "../createFetchHandler";
import { buildURL } from "../utils";
import useDeepEqualCallback from "../utils/useDeepEqualCallback";
import { RuntimeFetchArgs, FetchArgs, FetchConfig } from "types";

const root =
  process.env.STASH_API_PATH || process.env.REACT_APP_STASH_API_PATH || "";

const defaults = {
  root,
  method: httpMethods.GET
} as const;

export const simpleFetch = async (
  config: FetchConfig,
  createFetchHandler = defaultCreateFetchHandler
) => {
  const configWithDefaults = Object.assign({}, defaults, config);
  const url = buildURL(configWithDefaults, Auth.get());
  return await createFetchHandler(url, configWithDefaults)();
};

export function useFetch({
  createFetchHandler = defaultCreateFetchHandler,
  onSuccess,
  onError,
  ...userConfig
}: Partial<FetchArgs> = {}) {
  const [data, setData] = useState<any>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState<Boolean>(false);

  const fetch = useDeepEqualCallback(
    async (fetchFuncConfig?: RuntimeFetchArgs) => {
      const config = {
        ...defaults,
        ...userConfig,
        ...fetchFuncConfig
      };
      const url = buildURL(config, Auth.get());
      const fetchHandler = createFetchHandler(url, config);

      setLoading(true);
      try {
        const data = await fetchHandler();
        setData(data);
        setLoading(false);
        if (onSuccess) onSuccess(data);
        return data;
      } catch (e) {
        setError(e);
        setLoading(false);
        if (onError) onError(e);
      }
    },
    [userConfig, createFetchHandler]
  );

  useEffect(() => {
    if (userConfig.onMount) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    fetch,
    data,
    error,
    loading
  };
}

useFetch.POST = httpMethods.POST;
useFetch.PUT = httpMethods.PUT;
useFetch.PATCH = httpMethods.PATCH;
useFetch.GET = httpMethods.GET;
useFetch.DELETE = httpMethods.DELETE;
useFetch.HEAD = httpMethods.HEAD;
useFetch.OPTIONS = httpMethods.OPTIONS;
