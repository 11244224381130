import { useState, useCallback } from "react";
import useSWR from "swr";

import Auth from "../auth/index";
import httpMethods from "../utils/http";
import defaultCreateFetchHandler from "../createFetchHandler";
import { buildURL } from "../utils";
import { RuntimeFetchArgs, UseCachedGetFetchArgs } from "types";

let customDedupingIntervals: Record<string, number> = {};

const defaultDedupingInterval = 2000;

const defaultSwrOptions = {
  shouldRetryOnError: false,
  revalidateOnFocus: false
};

const root =
  process.env.STASH_API_PATH || process.env.REACT_APP_STASH_API_PATH || "";

const defaults = {
  root,
  method: httpMethods.GET
} as const;

export function useCachedGet({
  onMount,
  createFetchHandler = defaultCreateFetchHandler,
  swrOptions = {},
  onSuccess,
  onError,
  ...userConfig
}: Partial<UseCachedGetFetchArgs> = {}) {
  const [fetchArgs, setFetchArgs] = useState<false | RuntimeFetchArgs>(false);
  const config = Object.assign({}, defaults, userConfig, fetchArgs || {});

  const shouldGet = Boolean(fetchArgs) || onMount;

  let initialArg = null;
  let fetchHandler: any;
  let dedupingInterval = defaultDedupingInterval;
  const url = buildURL(config, Auth.get());

  if (shouldGet) {
    initialArg = url;
    fetchHandler = createFetchHandler(url, config);

    if (config.path && customDedupingIntervals[config.path]) {
      dedupingInterval = customDedupingIntervals[config.path];
    }
  }

  const options = {
    ...defaultSwrOptions,
    dedupingInterval,
    ...swrOptions
  };

  if (onError) options.onError = onError;
  if (onSuccess) options.onSuccess = onSuccess;

  const { data, error, isValidating, revalidate } = useSWR(
    initialArg,
    fetchHandler,
    options
  );

  const fetch = useCallback(
    (runtimeFetchArgs?: RuntimeFetchArgs): void => {
      setFetchArgs(runtimeFetchArgs || {});
    },
    [setFetchArgs]
  );

  return {
    fetch,
    data: data as any,
    error,
    loading: !shouldGet ? false : data === undefined && !error,
    isValidating,
    revalidate
  };
}

export const setDedupingIntervals = (
  intervals: Record<string, number>
): void => {
  customDedupingIntervals = Object.assign(
    {},
    customDedupingIntervals,
    intervals
  );
};
