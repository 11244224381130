import qs from "query-string";
import HTTPMethods from "./http";
import { FetchConfig } from "types";
import Auth from "../auth/index";
import postmanMockFilter from "../auth/postman";

export function buildURL(
  { root, path = "", params }: Partial<FetchConfig> = {},
  { userId, uuid }: ReturnType<typeof Auth.get>
) {
  const hasParams = params && Object.keys(params).length;
  const encodedParams = hasParams ? `?${qs.stringify(params!)}` : "";
  return `${root}${path}${encodedParams}`
    .replace(":user_id", userId || "")
    .replace(":uuid", uuid || "");
}

export function buildFetchConfig({
  method = HTTPMethods.GET,
  headers = {},
  body,
  mode = "cors",
  ...rest
}: FetchConfig | Partial<FetchConfig>) {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: Auth.get().accessToken || "",
      ...headers
    },
    mode: mode || "cors",
    body: body && JSON.stringify(body),
    method,
    ...rest
  } as const;
}

export async function sendRequest(url: string, fetchArgs: FetchConfig | Partial<FetchConfig>) {
  const fetchConfig = buildFetchConfig(fetchArgs);

  if (process.env.NODE_ENV !== "production") {
    // @ts-ignore
    if (fetchArgs.postman) return fetch(...postmanMockFilter(url, fetchConfig));
  }

  return fetch(url, fetchConfig);
}

export async function getJSON(res: Response) {
  if (res.status === 204) return "";
  const json = await res.json();
  return res.ok ? Promise.resolve(json) : Promise.reject(json);
}

export async function handleSessionChange(json: { [key: string]: any }) {
  if (json.api_key)
    Auth.set({
      accessToken: json.api_key.access_token,
      uuid: json.api_key.uuid,
      userId: json.api_key.user_id
    });
}

function parseJwt(token: string) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

function getExpiry(accessToken: string) {
  const parsed = parseJwt(accessToken.replace("Bearer ", ""));
  const { exp } = parsed;
  // multiply by 1000 to account for difference between Rails and JS dates
  return exp * 1000;
}

export function checkExpired(accessToken: string | undefined) {
  if (!accessToken) {
    return false;
  }
  const expiry = getExpiry(accessToken);
  return new Date().getTime() >= expiry - 2000;
}
