import { FetchConfig } from "types";

export interface Global {
  document: Document;
  window: Window;
  fetch: (url: string, config: any) => Promise<any>;
}

declare const global: Global;

type OnFetch = (request: RequestInit) => Promise<any>;

export const unmockFetch = () => {
  // @ts-ignore
  if (global.fetch && global.fetch.mockRestore) global.fetch.mockRestore();
};

export const mockFetch = (onFetch: OnFetch) => {
  unmockFetch();
  const mockFetch = async (url: string, config: FetchConfig) => {
    let parsedBody;
    try {
      parsedBody = JSON.parse(config.body);
    } catch (e) {}
    try {
      const json = await onFetch({
        ...new Request(url, config),
        // a slightly hacky convenience
        // to offer access to the body object, if it exists
        body: parsedBody
      });
      return Promise.resolve({
        ok: true,
        json() {
          return json;
        }
      });
    } catch (e) {
      return Promise.resolve({
        ok: false,
        json() {
          return e;
        }
      });
    }
  };
  jest.spyOn(global, "fetch").mockImplementation(mockFetch);
};
