import { getCookie, setCookie, deleteCookie } from "./cookies";
import cookieNames from "./stashDefaults";

const cookieTypes = Object.keys(cookieNames);
type cookieName = typeof cookieTypes[number];
type AuthData = Record<cookieName, string>;

const createAuthHandler = () => {
  // attempt to load values from cookies into memory on app start
  const data: Partial<AuthData> = {
    accessToken: getCookie(cookieNames.accessToken),
    refreshToken: getCookie(cookieNames.refreshToken),
    userId: getCookie(cookieNames.userId),
    uuid: getCookie(cookieNames.uuid)
  };

  const get = () => {
    // read values from cookies to keep in sync with main web repo
    return {
      accessToken: getCookie(cookieNames.accessToken),
      refreshToken: getCookie(cookieNames.refreshToken),
      userId: getCookie(cookieNames.userId),
      uuid: getCookie(cookieNames.uuid)
    } as Partial<AuthData>;
  };

  const set = (newData: AuthData) => {
    Object.keys(newData).forEach(key => {
      setCookie(cookieNames[key as keyof typeof cookieNames], newData[key]);
      data[key] = newData[key];
    });
  };

  const deleteAll = () => {
    Object.keys(data).forEach(key => {
      delete data[key];
      deleteCookie(cookieNames[key as keyof typeof cookieNames]);
    });
  };

  return {
    get,
    set,
    deleteAll
  };
};

export default createAuthHandler();
