import { sendRequest, handleSessionChange, getJSON, checkExpired } from './utils';
import { FetchConfig } from 'types';
import Auth from './auth/index';
import { BASE_URLS, CLIENT_IDS, REDIRECT_URLS } from './auth/constants';
import HTTPMethods from './utils/http';

const createFetchHandler = (url: string, config: FetchConfig) => {
	const fetchHandler = async () => {
		const authParams = Auth.get();
		const { accessToken, refreshToken } = authParams;
		if (!!refreshToken) {
			if (checkExpired(accessToken)) {
				// wipe the access token if it's expired
				Auth.set({
					accessToken: '',
				});
				const env: string = (window as any).Stash.env;
				const root = BASE_URLS[env];
				const client_id = CLIENT_IDS[env];
				const redirect_uri = REDIRECT_URLS[env];
				const tokenConfig = {
					method: HTTPMethods.POST,
					root,
					path: 'api/v1/token',
					headers: {
						Accept: 'application/json',
					},
					body: {
						grant_type: 'refresh_token',
						client_id,
						redirect_uri,
						refresh_token: refreshToken.replace('Bearer ', ''),
					},
				};
				const tokenUrl = `${root}api/v1/token`;
				const newAuth = await sendRequest(tokenUrl, tokenConfig);
				const payload = await getJSON(newAuth);
				Auth.set({
					accessToken: 'Bearer ' + payload.access_token,
					refreshToken: payload.refresh_token,
				});
			}
		}
		const response = await sendRequest(url, config);
		const json = await getJSON(response);
		await handleSessionChange(json);
		return json;
	};
	return fetchHandler;
};

export default createFetchHandler;
