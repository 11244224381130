const APP_HOSTNAME = window.location.hostname;
const domain = APP_HOSTNAME === "localhost" ? "" : `domain=.${APP_HOSTNAME};`;

interface CookieOptions {
  expires?: number;
  "max-age"?: number;
}
export const setCookie = (
  name: string,
  value: string,
  options?: CookieOptions
) => {
  const expires =
    (options && options.expires) ||
    (options && options["max-age"] && getExpires(options["max-age"])) ||
    getExpires(60 * 30);

  const encodedValue = encodeURIComponent(value);
  const isTest = process.env.NODE_ENV === "test"

  document.cookie = `${name}=${encodedValue}; expires=${expires};${isTest ? '' : ` secure=true; ${domain}`} path=/`;

  return value;
};

export const getCookie = (name: string) => {
  const value = "; " + document.cookie;
  const parts = value.split("; " + name + "=");
  return parts.length >= 2 ? decodeURIComponent(parts[1].split(";").shift() as string) : undefined;
};

export const deleteCookie = (name: string) => {
  return setCookie(name, "", { "max-age": -1 });
};

function getExpires(maxAge: number) {
  // convert a maxAge to an 'expires' date.
  return new Date(Date.now() + maxAge * 1000).toUTCString();
}
