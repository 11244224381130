import Auth from "./auth/index";
import stashDefaults from "./auth/stashDefaults";

export { unmockFetch, mockFetch } from "./testUtils";

export { useCachedGet, setDedupingIntervals } from "./useCachedGet";
export { useFetch, simpleFetch } from "./useFetch";

export {
  // in case people want to roll their own fetchHandler with createFetchHandler, they
  // can use these functions
  sendRequest,
  handleSessionChange,
  getJSON,
  buildURL
} from "./utils";

// delete auth cookies & in memory data
export const deleteAuthData = Auth.deleteAll;

// check there is in-memory auth data (but no guarantee it isn't stale)
export const hasAuthData = () => {
  const authData = Auth.get();
  return Object.keys(stashDefaults)
    .map(k => authData[k])
    .every(Boolean);
};
